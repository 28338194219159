@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-Bold.ttf");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-Light.ttf");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-Regular.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-Medium.ttf");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-SemiBold.ttf");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Ubuntu Mono";
  src: url("../fonts/Ubuntu Mono/UbuntuMono-Regular.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Afacad";
  src: url("../fonts/Afacad/Afacad-Regular.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Afacad";
  src: url("../fonts/Afacad/Afacad-Medium.ttf");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Afacad";
  src: url("../fonts/Afacad/Afacad-Bold.ttf");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Afacad";
  src: url("../fonts/Afacad/Afacad-SemiBold.ttf");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}
